import { store } from '@/store/store';
import { calendario_laboral } from '@/shared/dtos/calendario_laboral';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'calendario_laboralModule',
    store,
    dynamic: true
})
class calendario_laboralModule extends VuexModule {
    public calendario_laborales: calendario_laboral[] = [];
    public calendario_laboral: calendario_laboral = new calendario_laboral();

    @Action({ commit: 'onGetcalendario_laborales' })
    public async getcalendario_laborales() {
        return await ssmHttpService.get(API.calendario_laboral);
    }

    @Action({ commit: 'onGetcalendario_laboral' })
    public async getcalendario_laboral_empleado(id: any) {
        return await ssmHttpService.get(API.calendario_laboral + '/empleado/' + id);
    }

    @Action({ commit: 'onGetcalendario_laboral' })
    public async getcalendario_laboral(id: any) {
        return await ssmHttpService.get(API.calendario_laboral + '/' + id);
    }

    @Action
    public async guardarcalendario_laboral(calendario_laboral: calendario_laboral) {
       return await ssmHttpService.post(API.calendario_laboral, calendario_laboral.toJson());
    }

    @Action
    public async modificarcalendario_laboral(calendario_laboral: calendario_laboral) {
        return await ssmHttpService.put(API.calendario_laboral + '/' + calendario_laboral.id, calendario_laboral);
    }

    @Action
    public async eliminarcalendario_laboral(calendario_laboral: calendario_laboral) {
       return await ssmHttpService.delete(API.calendario_laboral + '/' + calendario_laboral.id, null, false);
    }

    @Mutation
    public onGetcalendario_laborales(res: calendario_laboral[]) {
        this.calendario_laborales = res ? res.map((x) => new calendario_laboral(x)) : []
    }

    @Mutation
    public onGetcalendario_laboral(res: calendario_laboral) {
        this.calendario_laboral = new calendario_laboral(res);
    }

}
export default getModule(calendario_laboralModule);